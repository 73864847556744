(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/wager-utils/assets/javascripts/wager-utils.js') >= 0) return;  svs.modules.push('/components/tipsen/wager-utils/assets/javascripts/wager-utils.js');
"use strict";


const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  rowToIndexes
} = svs.components.sport.tipsenShared;
const outcomeMap = {
  1: 0,
  X: 1,
  2: 2
};
const getSigns = (board, engineDefinition, sportType) => {
  var _engineDefinition$out;
  const mSigns = [];
  const signs = [];
  if (Array.isArray(board)) {
    board = board.join('');
  }
  const eventTypeId = engineDefinition.outcomes.eventTypeId;
  let outcomeBoardLength;
  const heatmapMaxValue = (_engineDefinition$out = engineDefinition.outcomes.outcomeHeatmap[sportType]) === null || _engineDefinition$out === void 0 ? void 0 : _engineDefinition$out.maxValue;
  if (heatmapMaxValue) {
    outcomeBoardLength = heatmapMaxValue + 1;
  } else {
    outcomeBoardLength = engineDefinition.outcomes.outcomeCount;
  }
  if (eventTypeId === EventTypeId.GOAL_COUNT) {
    signs.push(rowToIndexes(board.split(''), outcomeBoardLength));
  } else {
    mSigns.push(board.includes('M'));
    signs.push(rowToIndexes(board.replace('M', '').split('').map(n => outcomeMap[n]), outcomeBoardLength));
  }
  return [mSigns, signs];
};
const toExport = {
  getSigns,
  outcomeMap
};
setGlobal('svs.components.tipsen.wagerUtils', toExport);

 })(window);